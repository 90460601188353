<template>
  <div id="presentation-list">

    <div v-for="presentation in presentations" :key="presentation.id">
      <router-link :to="'/app/presentations/' + presentation.id">
        <ion-item detail class="presentation-list-item-modern">
          <ion-row style="width:100%">
            <template
                v-if="presentation.start_time && presentation.end_time && (presentation.start_time == presentation.end_time)">
            </template>
            <template v-else>
              <ion-col size="auto" style="text-align: center">
                <div style="padding-left: 10px;" class="timeslot">
                  <ion-icon
                      :name="'time-outline'"
                      class="time-icon"
                  ></ion-icon>
                  <span class="time"
                        v-show="presentation.show_time && presentation.start_time.split('T')[1]"
                  >{{ moment(presentation.start_time).format('HH:mm') }} </span>
                </div>
              </ion-col>
            </template>


            <ion-col size="10" class="content">
              <div>
                <span style="padding: 5px; padding-left: 10px;  font-size: 13px" class="w600 title"><span
                    v-if="presentation.code">{{ presentation.code }} - </span><span
                    v-html="presentation.title"></span></span>
                <span class="speaker-line" v-for="speaker in presentation.speakers"
                      :key="speaker.id">{{ speaker.display_name }}, <span
                    v-if="speaker.city">{{ speaker.city }}</span> <span
                    v-if="speaker.country">({{ speaker.country }})</span>
                </span>
                <p v-show="presentation.sess_start">{{
                    moment(presentation.sess_start).format('dddd, HH:mm')
                  }}{{ moment(presentation.sess_end).format('- HH:mm') }}
                  <span translate="UHR"></span></p>
                <p v-if="presentation.video_link && presentation.video_link != 'undefined' && showVideo "
                   style="margin-top:5px"><i class="ion-ios-videocam" style="font-size:1.2rem; color:#000"></i><span
                    style="color:#7F7E7E; font-size:1.1rem"> Video available</span></p>
                <p v-if="presentation.slides" style="padding-left: 5px; background-color: #dbdbdb; color:#000">Slides
                  available in presentation preview</p>
                <small v-if="presentation.note" style="font-style:italic; color: dimgrey; white-space: normal">
                  “{{ presentation.note }}“
                </small>
              </div>
            </ion-col>
          </ion-row>
        </ion-item>
      </router-link>
    </div>
  </div>
</template>
<script>
import {
  IonItem,
  IonIcon,
  IonRow,
  IonCol
} from "@ionic/vue";
import moment from 'moment';
import {addIcons} from "ionicons";
import {timeOutline} from "ionicons/icons";

addIcons({"time-outline": timeOutline});
export default {
  data() {
    return {}
  },
  components: {
    IonItem,
    IonIcon,
    IonRow,
    IonCol
  },
  props: ['presentations', 'showVideo'],
  created() {
    this.moment = moment;
    console.log('presentations')
  }

};
</script>
<style scoped>
a {
  text-decoration: none;
}
</style>