<template>

  <div class="list" style="margin-left:8px;">
      <div v-for="person in persons"  :key="person.id">
      <router-link :to="'/app/persons/' + person.id" class="wcpt-person-list-item"  v-if="!person.isLetter">
        <template v-if="picture">
       <img v-if="!person.vitae_picture" src="@/assets/icon/avatar.png">
       <img v-if="person.vitae_picture" :src="'https://fileserver-188ab.kxcdn.com/file/' + person.vitae_picture.split('/')[4]"
                             style="object-fit:cover">
        </template>
       <div class="person-item-content" :style="{left: picture ? '60px' : '0px', 'margin-left': picture ? '20px':'0'}">
            <div class="person-name">{{person.display_name}}</div> 
            <div v-if="person.country" class="person-country" style="margin-right:2px">({{person.country}})  </div>
            <div > <span v-if="person.institution_1" > {{person.institution_1}} </span> <span v-if="person.institution_2" >{{person.institution_2}}</span></div> 
       </div>
      </router-link>
<div class="web-person-divider" ng-if="person.isLetter" >
          <h1>{{person.letter}}</h1>
        </div>
      </div>
    </div>

</template>

<script>


export default {
  data(){
    return{
    }
  },
  props: ['persons', 'picture'],
  
};
</script>